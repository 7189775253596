import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import ModalStyles from "./styles";
import Close from "../../icons/close";

const Modal = ({ children, onClose }) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    const element = document.getElementById("root");
    const content = document.getElementById("kbk-content");
    const children = document.querySelectorAll("#kbk-page a, #kbk-page button");

    setContainer(element);
    content.style.overflow = "hidden";
    children.forEach((element) => {
      element.tabIndex = -1;
    });
    document.body.style.overflow = "hidden";

    return () => {
      content.style.overflow = "auto";
      document.body.style.overflow = "auto";
      children.forEach((element) => {
        element.tabIndex = "initial";
      });
    };
  }, []);

  const handleClose = () => {
    onClose();
  };

  if (!container) {
    return null;
  }

  return createPortal(
    <ModalStyles role="dialog">
      <div className="modal-inner">
        <div className="modal-header">
          <button className="modal-close" onClick={handleClose}>
            <Close />
          </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </ModalStyles>,
    container
  );
};

export default Modal;
