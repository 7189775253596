import React from "react";
import Styles from "./styled";
import { NavLink } from "react-router-dom";
import MusicNote16 from "../../icons/musicNote16";
import Drink from "../../icons/Drink";
import Event from "../../icons/Event";
import Phone from "../../icons/phone";

export default () => (
  <Styles>
    <ul>
      <li>
        <NavLink to="/songs/">
          <MusicNote16 />
          Songs
        </NavLink>
      </li>
      <li>
        <NavLink to="/menus/">
          <Drink />
          Menus
        </NavLink>
      </li>
      <li>
        <NavLink to="/events/">
          <Event />
          Events
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact/">
          <Phone />
          Contact
        </NavLink>
      </li>
    </ul>
  </Styles>
);
