import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    html, body {
        margin: 0;
        background: ${({ theme }) => theme.colors.dark};
        color: ${({ theme }) => theme.colors.light};
        font-family: futura-pt, sans-serif;
        font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (2100 - 320)));
        line-height: 1.45; 
        word-break: break-word;
        box-sizing: border-box;
        text-rendering: optimizeLegibility;
        scroll-behavior: smooth;
    }

    h1,h2,h3 {
        font-family: trade-gothic-next-compressed, sans-serif;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: .04rem;
    }
    h4, h5, h6 {
        font-family: futura-pt, sans-serif;
        font-weight: 600;
        line-height: 1.3;
    }
`;
