import * as React from "react";

function logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      viewBox="0 0 103.89 46.59"
      {...props}
    >
      <style>{".logo__st1{fill:#0ff}"}</style>
      <path
        d="M14.95 28.9l-1.93-9.71-1.05 3.55v6.16H8.9V5.73h3.06v11.13l3.02-11.13h2.99l-2.92 9.88 3.02 13.29h-3.12zm12.85 0l-3.3-11.1-.95-3.44V28.9h-2.78V5.73h2.88l2.95 11.13.88 3.48V5.73h2.74V28.9H27.8zm10.33.34c-2.63 0-4.53-1.95-4.53-4.73v-14.4c0-2.75 1.9-4.73 4.53-4.73s4.53 1.98 4.53 4.73v14.4c0 2.79-1.89 4.73-4.53 4.73zm1.44-19.41c0-.87-.53-1.5-1.44-1.5-.91 0-1.41.63-1.41 1.5v14.96c0 .9.49 1.5 1.41 1.5.91 0 1.44-.59 1.44-1.5V9.83zM51.7 28.9l-1.83-8.11-1.97 8.11h-2.95l3.2-11.52-2.99-11.65h3.13l1.69 8.45 1.93-8.45h2.92L51.77 16.9l3.02 12H51.7zm16.02 0H62.9V5.73h4.78c2 0 3.79 1.04 3.79 4.07v4.21c0 1.5-.53 2.57-1.83 3.06 1.34.56 1.86 1.64 1.86 3.1v4.66c.01 3.06-1.82 4.07-3.78 4.07zm.77-19.03c0-.9-.35-1.32-1.02-1.32H66v7.34h1.34c.74 0 1.16-.45 1.16-1.39V9.87zm.04 9.98c0-.9-.46-1.43-1.16-1.43H66v7.65h1.51c.67 0 1.02-.42 1.02-1.32v-4.9zm10.68 9.39c-2.63 0-4.53-1.95-4.53-4.73v-14.4c0-2.75 1.9-4.73 4.53-4.73s4.53 1.98 4.53 4.73v14.4c0 2.79-1.9 4.73-4.53 4.73zm1.44-19.41c0-.87-.53-1.5-1.44-1.5s-1.41.63-1.41 1.5v14.96c0 .9.49 1.5 1.41 1.5s1.44-.59 1.44-1.5V9.83zM92.77 28.9l-1.83-8.11-1.97 8.11h-2.95l3.2-11.52-2.98-11.65h3.13l1.69 8.45 1.93-8.45h2.92L92.85 16.9l3.02 12h-3.1z"
        fill="#e129a3"
      />
      <path
        className="logo__st1"
        d="M99.9 38.74h-8.28c-.24 0-.43-.19-.43-.43s.19-.43.43-.43h8.28c1.72 0 3.12-1.4 3.12-3.12V3.98c0-1.72-1.4-3.12-3.12-3.12H3.98C2.26.86.86 2.26.86 3.98v30.77c0 1.72 1.4 3.12 3.12 3.12h8.13c.24 0 .43.19.43.43s-.19.43-.43.43H3.98c-2.2 0-3.98-1.79-3.98-3.98V3.98A3.99 3.99 0 013.98 0H99.9c2.2 0 3.98 1.79 3.98 3.98v30.77a3.98 3.98 0 01-3.98 3.99z"
      />
      <path
        className="logo__st1"
        d="M13.39 38.23c.02.55-.25 1.05-.67 1.34l2.68.56v-3.6l-2.59.54c.33.27.56.69.58 1.16zM51.78 38.92h1.31l-.64-1.65zm-9.32-2.18h-.28v1.4h.26c.57 0 .88-.25.88-.71 0-.45-.31-.69-.86-.69zm-10.12 2.18h1.31l-.64-1.65zm29.81-2.19c-.85 0-1.54.71-1.54 1.58 0 .88.69 1.59 1.54 1.59.84 0 1.53-.71 1.53-1.59.01-.86-.68-1.58-1.53-1.58z"
      />
      <path
        className="logo__st1"
        d="M80.08 30.06h-.16a8.21 8.21 0 00-6.69 3.47l-.06.09c-.19-.7-.83-1.21-1.59-1.21h-.03c-.74 0-1.37.49-1.58 1.17v-.29l-51.93 1.45c-1.05.05-1.88.92-1.88 1.97v3.24c0 1.06.83 1.92 1.89 1.97l51.92 1.45v-.29c.2.68.83 1.17 1.58 1.17h.03c.76 0 1.39-.51 1.59-1.21l.06.09a8.21 8.21 0 006.69 3.47h.16c4.51 0 8.19-3.67 8.19-8.19v-.16c0-4.51-3.68-8.19-8.19-8.19zM24.22 40.62l-1.64-2.07-.11.11v1.96h-.9v-4.61h.9v1.95l1.69-1.95h1.13l-1.95 2.13 2.05 2.48h-1.17zm10.11 0l-.39-.97h-1.89l-.41.97h-.95l2-4.71h.68l1.93 4.71h-.97zm9.21 0l-1.24-1.85h-.12v1.85h-.9v-4.61h1.33c1.04 0 1.63.5 1.63 1.36 0 .7-.37 1.17-1.05 1.31l1.45 1.94h-1.1zm10.23 0l-.39-.97h-1.89l-.41.97h-.95l2-4.71h.68l1.93 4.71h-.97zm8.38.11c-1.4 0-2.47-1.04-2.47-2.4 0-1.35 1.08-2.42 2.47-2.42s2.46 1.06 2.46 2.4c0 1.36-1.08 2.42-2.46 2.42zm10.8-.11l-1.64-2.07-.11.11v1.96h-.9v-4.61h.9v1.95l1.69-1.95h1.13l-1.95 2.13 2.05 2.48h-1.17zm9.92-3.82h-1.65v1.02h1.59v.78h-1.59v1.24h1.65v.78h-2.55v-4.61h2.55v.79z"
      />
    </svg>
  );
}

export default logo;
