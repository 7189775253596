import React, { useCallback, useEffect, useState } from "react";
import Close from "../../icons/close";
import Modal from "../modal";
import EmailSignup from "../EmailSignup";
import BannerStyles from "./styles";

import { attributes as content } from "../../../content/banner.md";
import { useSegment } from "../../providers/segment";

const Banner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const segment = useSegment();

  const closeBanner = () => {
    setShowBanner(false);
    localStorage.setItem("bannerClosed", true);
    segment.track("Banner Closed");
  };

  const closeModal = () => {
    setShowModal(false);
    segment.track("Modal Closed");

    if (submitted) {
      closeBanner();
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
  };

  useEffect(() => {
    const bannerClosed = !!localStorage.getItem("bannerClosed");

    if (!bannerClosed) {
      setShowBanner(true);
    }
  }, []);

  const ctaClick = () => {
    setShowModal(true);
    segment.track("Modal Opened");
  };

  if (!showBanner) {
    return null;
  }

  return (
    <>
      {showModal && (
        <Modal onClose={closeModal}>
          <EmailSignup onSubmit={handleSubmit} />
        </Modal>
      )}
      <BannerStyles className="banner">
        <div className="banner-inner">
          <button className="banner-message" onClick={ctaClick}>
            {content.signupBanner.bannerMessage}
          </button>

          <button className="banner-close" onClick={closeBanner}>
            <Close />
          </button>
        </div>
      </BannerStyles>
    </>
  );
};

export default Banner;
