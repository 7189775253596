import styled from "styled-components";

export default styled.section`
  .email_signup {
    display: grid;
    grid-row-gap: 15px;
    max-width: 600px;

    &-heading {
      margin: 0;
      font-size: 2rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }

    &-content {
      margin: 0;
      display: grid;
      grid-row-gap: 10pxpx;

      > * {
        margin: 0;
      }
    }

    &-form {
      display: grid;
      grid-row-gap: 20px;
      padding: 1rem 0 0;
    }

    &-field {
      display: grid;
      grid-row-gap: 4px;

      p {
        margin: 0;
      }

      input {
        padding: 0.5rem 1rem;
        background: transparent;
        border: solid 2px ${({ theme }) => theme.colors.secondary};
        border-radius: 2px;
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.secondary};
        outline: none;
        transition: box-shadow 0.2s ease-in-out;
        width: 100%;
        box-sizing: border-box;

        &:focus,
        &:active {
          box-shadow: 0 0 15px ${({ theme }) => theme.colors.secondary};
        }
      }
    }

    &-submit {
      border-radius: 2px;
      border: none;
      padding: 0.5rem 1rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      color: #000;
      background-color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
      width: 100%;
      margin: auto;
      font-size: 1rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #fff;
      }

      &:disabled {
        opacity: 0.75;

        &:hover {
          color: #000;
        }
      }
    }

    &-disclaimer {
      font-size: .8rem;
      font-style: italic;
    }

    &-thank_you {
      display: grid;
      grid-row-gap: 15px;

      > * {
        margin: 0;
      }
    }

    &-qr {
      width: 100%:
      max-width: 200px;
      margin: 2rem auto 0;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }
`;
