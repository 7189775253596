import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Routes from "./routes";
import Page from "./components/page";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles";
import theme from "./theme";
import { SegmentProvider } from "./providers/segment";

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <SegmentProvider>
        <GlobalStyles />
        <Page>
          <Helmet titleTemplate="%s | Knox Box Karaoke" />
          <Routes />
        </Page>
      </SegmentProvider>
    </ThemeProvider>
  );
};
