export default {
  colors: {
    primary: "#69CCC9",
    secondary: "#E51394",
    dark: "#000000",
    light: "#FFFFFF",
    secondaryFill: "#bf0075",
  },
  breakpoints: {
    tablet: "512px",
    desktop: "992px",
  },
  padding: {
    inner: "25px",
  },
  shadows: {
    down: "-1px 1px 13px 3px #69CCC9",
    right: "0px 1px 12px 3px #69CCC9",
    up: "1px 1px 13px 3px #69CCC9",
  },
};
