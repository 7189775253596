import styled from "styled-components";

export default styled.header`
  width: 100%;
  background: ${({ theme }) => theme.colors.dark};
  margin: 0;
  box-shadow: ${({ theme }) => theme.shadows.down};

  .logo {
    height: 2.5rem;
    padding: 0.7rem 0 0.2rem 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      padding: 3rem 0 1rem 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    box-shadow: ${({ theme }) => theme.shadows.right};
  }
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      flex-direction: column;
    }
  }

  nav {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: block;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;
        margin: 0;
        font-family: trade-gothic-next-condensed, sans-serif;
        border-top: solid 1px ${({ theme }) => theme.colors.primary};

        a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 0;
          text-decoration: none;
          color: ${({ theme }) => theme.colors.secondary};
          transition: color 0.4s ease-in-out;
          text-transform: uppercase;

          &:hover,
          &:focus,
          &:active,
          &.active {
            color: ${({ theme }) => theme.colors.light};

            svg {
              path {
                stroke: ${({ theme }) => theme.colors.light};
              }
            }
          }

          svg {
            height: 1em;
            width: auto;
            margin-right: 10px;

            path {
              stroke: ${({ theme }) => theme.colors.secondary};
            }
          }
        }
      }
    }
  }
`;
