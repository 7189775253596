import React, { createContext, useContext, useEffect, useState } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { SegmentProviderProps } from "./types";

export const SegmentContext = createContext<AnalyticsBrowser | null>(null);

export const SegmentProvider: React.FC<SegmentProviderProps> = ({
  children,
}) => {
  const segment = AnalyticsBrowser.load({
    writeKey: `${process.env.SEGMENT_WRITE_KEY}`,
  });

  useEffect(() => {
    segment.identify();
  }, []);

  return (
    <SegmentContext.Provider value={segment}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegment = () => useContext(SegmentContext);
