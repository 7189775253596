import React from "react";
import Styles from "./styled";
import Facebook from "../../icons/facebook";
import Instagram from "../../icons/instagram";
import MapPin from "../../icons/mapPin";

import { attributes as content } from "../../../content/general.md";
import { useSegment } from "../../providers/segment";

export default () => {
  const segment = useSegment();

  return (
    <Styles>
      <section className="social">
        <a
          href={content.instagram}
          target="_blank"
          rel="noopener norefferer"
          aria-label="instagram"
          onClick={() => segment.track("Instagram Clicked")}
        >
          <Instagram />
        </a>
        <a
          href={content.facebook}
          target="_blank"
          rel="noopener norefferer"
          aria-label="facebook"
          onClick={() => segment.track("Facebook Clicked")}
        >
          <Facebook />
        </a>
        <a
          href="/contact"
          className="contactButton"
          onClick={() => segment.track("Footer Contact CTA Clicked")}
        >
          Contact
        </a>
      </section>
      <section className="contact">
        {/* Not sure if we need these things, not going to delete */}
        {/* <a href="tel:8652404183">
          <Phone />
          865.240.4183
        </a>
        <a href="mail:knoxboxkaraoke@gmail.com">
          <Mail />
          KnoxBoxKaraoke@gmail.com
        </a> */}
        <a
          href={content.mapLink}
          onClick={() => segment.track("Address Clicked")}
        >
          <MapPin />
          {content.businessAddress}
        </a>
      </section>
      <section className="copyright">
        <p>
          © {new Date().getFullYear()} {content.copyright || ""}
        </p>
      </section>
    </Styles>
  );
};
