import styled from "styled-components";

export default styled.section`
  display: grid;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  grid-template-rows: auto 1fr auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    height: 100vh;
  }

  > header {
    top: 0;
    z-index: 100;
  }

  > .content {
    overflow: auto;
  }

  > footer {
    position: sticky;
    bottom: 0;
    z-index: 100;

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      display: none;
    }
  }
`;
