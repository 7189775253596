import React from "react";
import Styles from "./styled";
import { Link } from "react-router-dom";
import KnoxBoxFlicker from "../../components/KnoxBoxFlicker";
import Layout from "../../components/shared/layout";

export default Styles(({ className }) => (
  <Layout className={className}>
    <KnoxBoxFlicker />
    <section className="content">
      <h1>Oops, we can't find that page.</h1>
      <Link to="/">Go home</Link>
    </section>
  </Layout>
));
