import styled from 'styled-components';

export default styled.svg`
  opacity: 1;
  animation: opacity 0.9s ease-in-out;

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Draw In 1x and Stop */
  .drawIn1x {
    stroke-dasharray: 3500;
    animation: drawIn1x 4s ease-in-out;
    animation-iteration-count: 1;
  }

  @keyframes drawIn1x {
    from {
      stroke-dashoffset: 3300;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  /* Different Flickers for sign */
  .flickerAll {
    animation: flicker0 3s step-end;
    animation-delay: 5.05s;
  }
  .flicker0 {
    animation: flicker0 8s step-end;
    animation-delay: 3s;
  }
  .flicker1 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 0s;
  }
  .flicker2 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 0.5s;
  }
  .flicker3 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 1.5s;
  }
  .flicker4 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 2s;
  }
  .flicker5 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 2.5s;
  }
  .flicker6 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 3s;
  }
  .flicker7 {
    opacity: 0;
    animation: flicker 5s infinite step-end;
    animation-delay: 3.5s;
  }

  @keyframes flicker {
    0% {
      opacity: 0.8;
    }
    3% {
      opacity: 0.4;
    }
    6% {
      opacity: 1;
    }
    7% {
      opacity: 1;
    }
    8% {
      opacity: 1;
    }
    9% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    89% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flicker0 {
    0% {
      opacity: 0.8;
    }
    3% {
      opacity: 0.4;
    }
    6% {
      opacity: 1;
    }
    7% {
      opacity: 1;
    }
    8% {
      opacity: 1;
    }
    9% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    89% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;
