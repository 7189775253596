import styled from "styled-components";

export default styled.section`
  display: flex;
  padding: 6rem 2rem;

  svg {
    margin: 0 auto;
    height: auto;
  }
`;
