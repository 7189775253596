import styled from "styled-components";

export default styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  padding: 0 ${({ theme }) => theme.padding.inner};
  box-sizing: border-box;

  .modal-inner {
    max-width: 600px;
    max-height: 70%;
    margin: auto;
    width: calc(100% - 100px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    overflow: auto;
    display: grid;
    grid-template-rows: max-content 1fr;
    overflow: visible;
    background: #000;
    border: solid 4px ${({ theme }) => theme.colors.secondary};
    border-radius: 4px;
    padding: 0 1rem;
    box-shadow: 0 0 15px ${({ theme }) => theme.colors.secondary};
  }

  .modal-header {
    display: grid;
    justify-content: end;
    padding: 0.5rem 0;

    .modal-close {
      background: transparent;
      border: none;
      padding: 0.5rem;
      margin-right: -0.5rem;
      color: #fff;
      cursor: pointer;
    }
  }

  .modal-content {
    overflow-y: auto;
    position: relative;
    padding: 0 1rem 2rem;
  }
`;
