import styled from "styled-components";

export default styled.footer`
  box-shadow: 0 -3px 5px ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.dark};

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    li {
      border-right: solid 1px ${({ theme }) => theme.colors.primary};

      &:last-child {
        border: none;
      }

      a {
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease-in-out;
        color: ${({ theme }) => theme.colors.secondary};
        font-family: trade-gothic-next-compressed, sans-serif;
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.03rem;
        text-decoration: none;
        text-transform: uppercase;
        gap: 5px;

        &:hover,
        &:active,
        &:focus,
        &.active {
          background: ${({ theme }) => theme.colors.secondary};
          color: ${({ theme }) => theme.colors.light};
        }

        svg {
          height: 1.15rem;
          width: auto;

          path {
            stroke: currentColor;
          }
        }
      }
    }
  }
`;
