import styled from "styled-components";

export default (comp) => styled(comp)`
  .content {
    margin-top: -50px;
    text-align: center;
    margin-bottom: 100px;

    h1 {
      text-transform: uppercase;
      font-size: 2rem;
      margin: 1rem 0;
      margin-bottom: 0.5rem;
    }

    a {
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.secondary};
      text-decoration: none;
    }
  }
`;
