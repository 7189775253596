import React from "react";
import Styles from "./styled";
import LogoAnimated from "../../icons/LogoAnimated";

const KnoxBoxFlicker = () => (
  <Styles className="knox_box_flicker">
    <LogoAnimated />
  </Styles>
);

export default KnoxBoxFlicker;
