import React from "react";
import Styles from "./styled";
import Header from "../header";
import MobileHeader from "../mobileHeader";

export default ({ children }) => (
  <Styles id="kbk-page">
    <Header />
    <section className="content" id="kbk-content">
      {children}
    </section>
    <MobileHeader />
  </Styles>
);
