import * as React from "react";

function musicNote16(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.31}
      height={18.87}
      viewBox="0 0 22.31 18.87"
      overflow="visible"
      {...props}
    >
      <style>
        {
          ".musicNote16__st0{fill:none;stroke:#e129a3;stroke-width:1.5;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="musicNote16__st0"
        d="M6.01 15.15c-.18 1.09-1.5 1.78-2.94 1.55C1.62 16.46.6 15.38.78 14.29c.18-1.09 1.5-1.78 2.94-1.55 1.44.24 2.47 1.32 2.29 2.41zM18.97 16.53c-.18 1.09-1.5 1.78-2.94 1.55-1.45-.24-2.47-1.32-2.29-2.41.18-1.09 1.5-1.78 2.94-1.55s2.47 1.32 2.29 2.41z"
      />
      <path
        className="musicNote16__st0"
        d="M6.01 15.15L7.92 3.56l13.21-.18-2.16 13.15M7.92 3.56L8.35.93 21.56.75l-.43 2.63"
      />
    </svg>
  );
}

export default musicNote16;
