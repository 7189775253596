import * as React from "react";

function Drink(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      viewBox="0 0 26.33 30.17"
    >
      <style>
        {
          ".prefix__st0{fill:none;stroke:#e129a3;stroke-width:2;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="prefix__st0"
        d="M12.02 24.48L19.89.31m1.66 28.02s-2.03.84-7.86.84-8.92-.84-8.92-.84c-.84 0-1.54-.65-1.6-1.49L1 3.75c-.07-1.84 24.39-1.94 24.33 0l-2.17 23.1a1.62 1.62 0 01-1.61 1.48z"
      />
      <path
        className="prefix__st0"
        d="M24.36 13.96s-3.8 3.29-9.64 1.11c-10.55-3.95-12.51.1-12.51.1M1 4.49c3.04 1.96 22.61 1.99 24.33 0"
      />
    </svg>
  );
}

export default Drink;
