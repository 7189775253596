import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { QRCodeSVG } from "qrcode.react";
import EmailSignupStyles from "./styles";

import { attributes as content } from "../../../content/banner.md";
import { useSegment } from "../../providers/segment";

const EmailSignup = ({ onSubmit }) => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const segment = useSegment();

  const handleSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();

    const formData = Object.fromEntries(new FormData(e.target));
    segment.identify(formData.email);
    segment.track("Sign Up Form Submitted", formData);

    onSubmit?.();
  };

  useEffect(() => {
    var emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return setEmailValid(emailRegex.test(email));
  }, [email]);

  return (
    <EmailSignupStyles className="email_signup">
      {!submitted ? (
        <>
          <h2 className="email_signup-heading">
            {content.signupBanner.heading}
          </h2>
          {content.signupBanner.content && (
            <div className="email_signup-content">
              <ReactMarkdown>{content.signupBanner.content}</ReactMarkdown>
            </div>
          )}
          <form className="email_signup-form" onSubmit={handleSubmit}>
            <label className="email_signup-field">
              <p>Email</p>
              <input
                type="text"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </label>

            <button
              className="email_signup-submit"
              type="submit"
              disabled={!emailValid}
            >
              Submit
            </button>
            <p className="email_signup-disclaimer">
              {content.signupBanner.disclaimer}
            </p>
          </form>
        </>
      ) : (
        <div className="email_signup-thank_you">
          <h2 className="email_signup-heading">
            {content.signupBanner.thankYouHeading}
          </h2>
          <ReactMarkdown>{content.signupBanner.thankYouContent}</ReactMarkdown>
          <div className="email_signup-qr">
            <QRCodeSVG
              value={`${window.location.origin}/discounts/?email=${email}`}
              bgColor="#000"
              fgColor="#fff"
              size={200}
            />
          </div>
        </div>
      )}
    </EmailSignupStyles>
  );
};

export default EmailSignup;
