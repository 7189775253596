import React from "react";
import { Link } from "react-router-dom";
import Styles from "./styled";
import Logo from "../../icons/logo";
import MusicNote16 from "../../icons/musicNote16";
import Drink from "../../icons/Drink";
import Event from "../../icons/Event";
import Phone from "../../icons/phone";

export default () => (
  <Styles>
    <section className="inner">
      <Link to="/" aria-label="home">
        <Logo className="logo" />
      </Link>
      <nav className="header">
        <ul>
          {/* <li><Link to="/songs/"><MusicNote16 />Songs</Link></li> */}
          <li>
            <Link to="/songs/">
              <MusicNote16 />
              Songs
            </Link>
          </li>
          <li>
            <Link to="/menus/">
              <Drink />
              Menu
            </Link>
          </li>
          <li>
            <Link to="/events/">
              <Event />
              Events
            </Link>
          </li>
          <li>
            <Link to="/contact/">
              <Phone />
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  </Styles>
);
