import * as React from 'react';
import Styles from './styled';

function LogoAnimated() {
  return (
    <Styles
      width={784}
      height={361}
      viewBox="0 0 784 361"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="opacity"
    >
      <g className="flicker0">
        <path
          d="M106.64 288.72c.15 4.09-1.85 7.76-4.99 9.93l19.86 4.12v-26.62l-19.19 3.98c2.52 2.04 4.19 5.11 4.32 8.59z"
          fill="#0FF"
        />
        <g className="flicker0" filter="url(#logo__filter0_d)" fill="#0FF">
          <path d="M390.85 293.89h9.72l-4.75-12.28-4.97 12.28zM321.82 277.72h-2.05v10.33h1.94c4.25 0 6.5-1.84 6.5-5.27.01-3.32-2.24-5.06-6.39-5.06zM246.91 293.89h9.72l-4.76-12.28-4.96 12.28zM467.63 277.67c-6.29 0-11.41 5.27-11.41 11.71 0 6.5 5.12 11.77 11.41 11.77 6.24 0 11.36-5.27 11.36-11.77 0-6.44-5.06-11.71-11.36-11.71z" />
          <path d="M600.35 228.29h-1.16c-19.65 0-38.17 9.6-49.52 25.67l-.45.64c-1.42-5.16-6.14-8.97-11.74-8.97h-.19c-5.5 0-10.16 3.66-11.67 8.68v-2.16L141.2 262.88c-7.81.37-13.92 6.79-13.92 14.6v23.96c0 7.81 6.11 14.23 13.98 14.61l384.36 10.73v-2.16c1.51 5.01 6.17 8.68 11.67 8.68h.19c5.61 0 10.33-3.81 11.74-8.97l.45.64c11.36 16.07 29.87 25.67 49.52 25.67h1.16c33.41 0 60.6-27.18 60.6-60.6v-1.16c0-33.41-27.18-60.59-60.6-60.59zm-413.53 78.18l-12.12-15.35-.82.82v14.53h-6.65v-34.12h6.65v14.43l12.53-14.43h8.39l-14.43 15.76 15.19 18.36h-8.74zm74.82 0l-2.92-7.21h-13.96l-3.07 7.21h-7.06l14.78-34.84h5.01l14.32 34.84h-7.1zm68.21 0l-9.21-13.71h-.87v13.71h-6.65v-34.12H323c7.67 0 12.07 3.68 12.07 10.08 0 5.22-2.76 8.64-7.78 9.67l10.74 14.37h-8.18zm75.74 0l-2.92-7.21h-13.96l-3.07 7.21h-7.06l14.78-34.84h5.01l14.32 34.84h-7.1zm62.04.77c-10.33 0-18.26-7.72-18.26-17.75 0-10.03 8.03-17.9 18.26-17.9 10.23 0 18.21 7.83 18.21 17.8 0 10.02-8.03 17.85-18.21 17.85zm79.93-.77l-12.12-15.35-.82.82v14.53h-6.65v-34.12h6.65v14.43l12.53-14.43h8.39l-14.43 15.76 15.19 18.36h-8.74zm73.43-28.34h-12.23v7.57h11.77v5.78h-11.77v9.21h12.23v5.78h-18.88v-34.12h18.88v5.78z" />
        </g>
      </g>
      <g className="flickerAll">
        <g filter="url(#logo__filter1_d)" className="flicker1">
          <path
            d="M118.18 219.65l-14.3-71.85-7.8 26.27v45.58H73.45V48.13h22.63v82.41l22.37-82.41h22.11l-21.59 73.14 22.37 98.38h-23.16z"
            fill="#E129A3"
          />
        </g>
        <g filter="url(#logo__filter2_d)" className="flicker2">
          <path
            d="M213.37 219.65l-24.45-82.15-7.02-25.5v107.65h-20.55V48.13h21.33l21.85 82.41 6.5 25.75V48.13h20.29v171.52h-17.95z"
            fill="#E129A3"
          />
        </g>
        <g filter="url(#logo__filter3_d)" className="flicker3">
          <path
            d="M289.83 222.22c-19.51 0-33.55-14.42-33.55-35.03V80.58c0-20.35 14.04-35.03 33.55-35.03 19.51 0 33.55 14.68 33.55 35.03V187.2c0 20.6-14.05 35.02-33.55 35.02zm10.66-143.7c0-6.44-3.9-11.07-10.66-11.07s-10.4 4.64-10.4 11.07v110.74c0 6.7 3.64 11.07 10.4 11.07s10.66-4.38 10.66-11.07V78.52z"
            fill="#E129A3"
          />
        </g>
        <g filter="url(#logo__filter4_d)" className="flicker4">
          <path
            d="M390.24 219.65l-13.52-60.01-14.56 60.01h-21.85l23.67-85.25-22.11-86.28h23.15l12.48 62.58 14.3-62.58h21.59l-22.63 82.67 22.37 88.85h-22.89v.01z"
            fill="#E129A3"
          />
        </g>
        <g filter="url(#logo__filter5_d)" className="flicker5">
          <path
            d="M508.83 219.65H473.2V48.13h35.37c14.82 0 28.09 7.73 28.09 30.13v31.16c0 11.07-3.9 19.06-13.52 22.66 9.88 4.12 13.78 12.1 13.78 22.92v34.51c0 22.67-13.52 30.14-28.09 30.14zm5.72-140.88c0-6.7-2.6-9.79-7.54-9.79h-10.92v54.34h9.88c5.46 0 8.58-3.35 8.58-10.3V78.77zm.26 73.92c0-6.7-3.38-10.56-8.58-10.56h-10.14v56.66h11.18c4.94 0 7.54-3.09 7.54-9.79v-36.31z"
            fill="#E129A3"
          />
        </g>
        <g filter="url(#logo__filter6_d)" className="flicker6">
          <path
            d="M593.87 222.22c-19.51 0-33.55-14.42-33.55-35.03V80.58c0-20.35 14.04-35.03 33.55-35.03 19.51 0 33.55 14.68 33.55 35.03V187.2c0 20.6-14.04 35.02-33.55 35.02zm10.67-143.7c0-6.44-3.9-11.07-10.66-11.07s-10.4 4.64-10.4 11.07v110.74c0 6.7 3.64 11.07 10.4 11.07s10.66-4.38 10.66-11.07V78.52z"
            fill="#E129A3"
          />
        </g>
        <g filter="url(#logo__filter7_d)" className="flicker7">
          <path
            d="M694.29 219.65l-13.52-60.01-14.56 60.01h-21.85l23.67-85.25-22.11-86.28h23.15l12.48 62.58 14.3-62.58h21.59l-22.63 82.67 22.37 88.85h-22.89v.01z"
            fill="#E129A3"
          />
        </g>
      </g>
      <g filter="url(#logo__filter8_d)" className="flicker0">
        <path
          d="M97.77 289.47H38.16c-15 0-27.16-12.16-27.16-27.16V36.16C11 21.16 23.16 9 38.16 9H745.6c15 0 27.16 12.16 27.16 27.16v226.15c0 15-12.16 27.16-27.16 27.16h-59.61"
          stroke="#0FF"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="drawIn1x"
        />
      </g>
      <defs>
        <filter
          id="logo__filter0_d"
          x={119.28}
          y={222.29}
          width={549.67}
          height={138.35}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0.131389 0 0 0 0 0.716667 0 0 0 0 0.713031 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter1_d"
          x={64.45}
          y={41.13}
          width={85.89}
          height={189.52}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter2_d"
          x={152.35}
          y={41.13}
          width={87.97}
          height={189.52}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter3_d"
          x={247.28}
          y={38.55}
          width={85.1}
          height={194.67}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter4_d"
          x={331.31}
          y={41.12}
          width={91.08}
          height={189.53}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter5_d"
          x={464.2}
          y={41.13}
          width={81.72}
          height={189.52}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter6_d"
          x={551.32}
          y={38.55}
          width={85.1}
          height={194.67}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter7_d"
          x={635.36}
          y={41.12}
          width={91.08}
          height={189.53}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4.5} />
          <feColorMatrix values="0 0 0 0 0.720833 0 0 0 0 0.0600694 0 0 0 0 0.432055 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="logo__filter8_d"
          x={0}
          y={0}
          width={783.76}
          height={302.47}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0.131389 0 0 0 0 0.716667 0 0 0 0 0.713031 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </Styles>
  );
}

export default LogoAnimated;
