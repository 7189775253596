import loadable from "@loadable/component";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import Loading from "./components/loading";
import FourOhFour from "./pages/fourOhFour";
import { useSegment } from "./providers/segment";

export default () => {
  const location = useLocation();
  const segment = useSegment();

  useEffect(() => {
    segment.page();
  }, [location.pathname]);

  return (
    <section style={{ position: "relative" }}>
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route
            exact
            path="/"
            component={loadable(() => import("./pages/home"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/menus/"
            component={loadable(() => import("./pages/menus"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/menus/food/"
            component={loadable(() => import("./pages/food"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/menus/drinks/"
            component={loadable(() => import("./pages/drinks"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/events/"
            component={loadable(() => import("./pages/events"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/contact/"
            component={loadable(() => import("./pages/contact"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/songs/"
            component={loadable(() => import("./pages/songs"), {
              fallback: <Loading />,
            })}
          />
          <Route
            exact
            path="/discounts/"
            component={loadable(() => import("./pages/discounts"), {
              fallback: <Loading />,
            })}
          />
          <Route path="*">
            <FourOhFour />
          </Route>
        </Switch>
      </AnimatePresence>
    </section>
  );
};
