import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import SocialFooter from "../../socialFooter";
import Banner from "../../banner";

export default ({ children, className }) => {
  const history = useHistory();
  const layoutRef = useRef();

  useEffect(() => {
    if (
      history.location.pathname !== "/menus" ||
      !history.location.search.includes("section=food")
    ) {
      setTimeout(() => {
        layoutRef.current.scrollIntoView();
      }, 100);
    }
  }, [history]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, position: "absolute" }}
      transition={{ duration: 0.3 }}
      style={{ margin: 0, width: "100%", top: 0 }}
      className={className}
      ref={layoutRef}
    >
      <Banner />
      {children}
      <SocialFooter />
    </motion.main>
  );
};
