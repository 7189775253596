import styled from "styled-components";

export default styled.section`
  display: grid;
  grid-row-gap: 10px;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.up};
  padding: 24px 20px;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-row-gap: 0;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 30px;
    text-align: left;
    padding: 10px 30px 10px 20px;
    align-items: center;
  }

  .social {
    svg {
      height: 2rem;
      width: auto;
    }
  }

  .contactButton {
    border-radius: 16px;
    padding: 0.2rem 0.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.04rem;

    color: #000;
    background-color: ${({ theme }) => theme.colors.secondary};
    &:hover {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .contact {
    a {
      display: inline-block;
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  svg {
    vertical-align: middle;
    height: 1em;
    width: auto;
    margin-right: 0.3rem;
    padding-bottom: 0.3rem;
  }

  p {
    color: ${({ theme }) => theme.colors.secondary};
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
    transition: all 0.4s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
