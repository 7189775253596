import styled from "styled-components";

export default styled.section`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0 ${({ theme }) => theme.padding.inner};

  .banner-inner {
    margin: auto !important;
    max-width: 1200px;
    text-align: center;
    margin-right: 50px;
    position: relative;
    padding: 0.5rem 0;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 27px;
    grid-column-gap: 10px;

    .banner-close {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .banner-message {
      margin: 0;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;
