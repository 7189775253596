import * as React from "react";

function Event(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      overflow="visible"
      viewBox="0 0 26.4 23.54"
      {...props}
    >
      <path
        d="M24.2 22.79h-22c-.8 0-1.45-.65-1.45-1.45V2.2C.75 1.4 1.4.75 2.2.75h22c.8 0 1.45.65 1.45 1.45v19.14c0 .8-.64 1.45-1.45 1.45zM.53 7.34h24.9"
        fill="none"
        stroke="#e129a3"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        fill="none"
        stroke="#e129a3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M8.28 14.25l3.77 3.77 6.71-6.7"
      />
      <path
        fill="none"
        stroke="#e129a3"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeMiterlimit={10}
        d="M8.05 4.09h10.3"
      />
    </svg>
  );
}

export default Event;
