export default () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.42893 0.928932C0.233671 1.12419 0.233671 1.44078 0.42893 1.63604L6.79289 8L0.42893 14.364C0.233671 14.5592 0.233671 14.8758 0.42893 15.0711C0.624189 15.2663 0.940779 15.2663 1.13604 15.0711L7.5 8.70711L13.864 15.0711C14.0592 15.2663 14.3758 15.2663 14.5711 15.0711C14.7663 14.8758 14.7663 14.5592 14.5711 14.364L8.20711 8L14.5711 1.63604C14.7663 1.44078 14.7663 1.12419 14.5711 0.928932C14.3758 0.73367 14.0592 0.73367 13.864 0.928932L7.5 7.29289L1.13604 0.928932C0.940771 0.73367 0.624189 0.73367 0.42893 0.928932Z"
      fill="currentColor"
    />
  </svg>
);
